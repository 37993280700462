
import { Vue, Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import AuthModule from '@/store/modules/Auth';
import GeneralModule from '@/store/modules/General';

@Component
export default class ErrorHandleMixin extends Vue {
  GeneralInstance = getModule(GeneralModule, this.$store);

  AuthInstance = getModule(AuthModule, this.$store);

  async checkError({ code, message }: { code: number; message: string }) {
    if (code || code === 0) {
      if (code === 1002) {
        await this.AuthInstance.Logout();
        this.GeneralInstance.toggleLoading(false);
        this.$router.push('/');
      }
      this.GeneralInstance.ShowAlert({
        isAlert: true,
        alertMsg: message,
        alertColor: code === 0 ? 'success' : 'danger',
      });
    }
  }
}
